@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.a50d8eb2.ttf");
}

* {
  font-family: Montserrat;
}

body {
  margin: 0;
}

.white {
  color: #fff;
}

section {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
}

.Block div {
  max-width: min-content;
}

.Section-margin {
  margin-top: 90px;
}

.li-margins {
  margin-bottom: 24px;
}

.industries-numbering-text {
  color: #ff5959;
  width: 30px;
  margin-right: 27px;
}

.footer-li {
  padding-bottom: 23px;
}

@media screen and (width >= 900px) {
  * {
    letter-spacing: -.005em;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  h1 {
    margin: 0;
    font-size: 60px;
    line-height: 60px;
  }

  h2 {
    margin: 0;
    font-size: 48px;
    line-height: 59px;
  }

  body {
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  .footer-text li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .years {
    letter-spacing: -.015em;
    margin-top: -50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
  }

  .navBar-list {
    flex-direction: row;
    flex-basis: 80%;
    justify-content: space-between;
    display: flex;
  }

  .flex-half {
    flex-basis: 50%;
  }

  .Business-card-style-line {
    width: 275px;
  }

  .card-media-Industries {
    flex-direction: row;
    justify-content: space-around;
    display: flex;
  }

  .navBar {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .nav-bar-logo {
    align-self: center;
    max-width: 100%;
    height: max-content;
    display: flex;
  }

  .mobile-menu-btn {
    display: none;
  }

  .card-media-direction {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .Block-industries {
    background: #1c1e3e;
    border-radius: 20px;
    width: 75%;
    min-width: fit-content;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .card-media {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .Style-line-mobile {
    display: none;
  }

  .Business-card-Title-Wrapper {
    flex-direction: column;
    padding-top: 5%;
    padding-left: 5%;
    display: flex;
  }

  .Business-card-Tagline {
    color: #fff;
    margin-top: 47px;
  }

  .navBar-li {
    letter-spacing: -.015em;
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-decoration: none;
    display: flex;
  }

  .navBar-li a {
    letter-spacing: -.015em;
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-decoration: none;
  }

  .navBar-ul {
    flex-direction: row;
    flex-basis: 40%;
    justify-content: space-around;
    display: flex;
  }

  .About-company-style-line {
    width: 240px;
  }

  .Row {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Block {
    letter-spacing: -.005em;
    background: #f5f5fa;
    border-radius: 20px;
    flex-direction: row;
    flex: 0 0 32%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
  }

  .Block-img {
    width: 70px;
    height: 70px;
    padding-left: 50px;
    padding-right: 30px;
  }
}

@media screen and (width <= 900px) {
  * {
    letter-spacing: -.005em;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  h1, h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }

  .card-media-Industries {
    flex-direction: column;
    justify-content: space-around;
    padding-left: 27px;
    display: flex;
  }

  .card-media-direction {
    flex-direction: column-reverse;
    justify-content: space-between;
    display: flex;
  }

  .Block-industries {
    background: #1c1e3e;
    border-radius: 20px;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .Business-card-style-line {
    width: 150px;
  }

  .card-media {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  .Style-line-mobile {
    float: right;
    display: block;
  }

  .Style-line {
    width: 100%;
    display: none;
  }

  .Business-card-Title-Wrapper {
    flex-direction: column;
    padding: 38px 27px;
    display: flex;
  }

  .Business-card-Tagline {
    color: #fff;
    letter-spacing: -.005em;
    margin-top: 30px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  .About-company-style-line {
    width: 160px;
  }

  section:not(:first-child) {
    margin-bottom: 67px;
  }

  .footer-text {
    padding-left: 0;
  }

  .navBar {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .section-header {
    z-index: 1;
    background: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
  }

  .navBar-list {
    display: none;
  }

  .navBar-li, .navBar-li a {
    color: #303030;
    letter-spacing: -.015em;
    font-size: 20px;
    font-weight: 500;
    line-height: 95%;
    text-decoration: none;
    display: flex;
  }

  body {
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
  }

  .Block {
    letter-spacing: -.005em;
    background: #f5f5fa;
    border-radius: 20px;
    flex-direction: row;
    flex: 0 0 32%;
    align-items: center;
    margin-bottom: 15px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 48px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
  }

  .Block-img {
    width: 70px;
    height: 70px;
    padding-right: 30px;
  }
}

.modal {
  z-index: 11;
  background: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

.modal-header {
  padding: 26px 36px 56px 16px;
}

#btn-close {
  float: right;
}

.modal-body {
  padding: 2px 16px;
}

.modal-content {
  z-index: 3;
  background-color: #fff;
  width: 0;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

.sub-menu {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  display: none;
  position: absolute;
  top: 16px;
}

#our-products-desktop-menu:hover .sub-menu, .sub-menu:hover {
  display: block;
}

#our-products-desktop-menu:hover {
  cursor: pointer;
  position: relative;
}

.section-header {
  z-index: 10;
  background: #fff;
  position: sticky;
  top: 0;
}

.DataSan-menu {
  color: #000;
  font-style: normal;
  text-decoration: none;
}
/*# sourceMappingURL=data-san.96f69d51.css.map */
