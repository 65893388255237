@font-face {
    font-family: Montserrat;
    src: url(Fonts/Montserrat/Montserrat-Regular.ttf);
}

* {
    font-family: Montserrat;
}

body {
    margin: 0;
}

.white {
    color: #FFFFFF;
}

section {
    margin-top: 10px;
    margin-bottom: 10px
}

ul {
    list-style-type: none;
}

.Block div {
    max-width: min-content;
}


.Section-margin {
    margin-top: 90px;
}


.li-margins {
    margin-bottom: 24px;
}

.industries-numbering-text {
    color: #FF5959;
    width: 30px;
    margin-right: 27px;
}

.footer-li {
    padding-bottom: 23px;
}


@media screen and (min-width: 900px) {
    * {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.005em;
    }

    h1 {
        font-size: 60px;
        line-height: 60px;
        margin: 0
    }

    h2 {
        font-size: 48px;
        line-height: 59px;
        margin: 0
    }

    body {
        margin: 0;
        padding-right: 5%;
        padding-left: 5%;
    }

    .footer-text li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
    }

    .years {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.015em;
        margin-top: -50px;
    }

    .navBar-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-basis: 80%;
    }

    .flex-half {
        flex-basis: 50%;
    }

    .Business-card-style-line {
        width: 275px;
    }

    .card-media-Industries {
        flex-direction: row;
        display: flex;
        justify-content: space-around;
    }

    .navBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .nav-bar-logo {
        display: flex;
        max-width: 100%;
        height: max-content;
        align-self: center;
    }

    .mobile-menu-btn {
        display: none;
    }

    .card-media-direction {
        flex-direction: row;
        display: flex;
        justify-content: space-between
    }

    .Block-industries {
        padding-top: 5%;
        padding-bottom: 5%;
        background: #1C1E3E;
        width: 75%;
        min-width: fit-content;
        border-radius: 20px;
    }

    .card-media {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }

    .Style-line-mobile {
        display: none;
    }

    .Business-card-Title-Wrapper {
        display: flex;
        padding-top: 5%;
        padding-left: 5%;
        flex-direction: column
    }

    .Business-card-Tagline {
        margin-top: 47px;
        color: white;
    }

    .navBar-li {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: -0.015em;
        text-decoration: none;
        color: #303030;
    }

    .navBar-li a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: -0.015em;
        text-decoration: none;
        color: #303030;
    }

    .navBar-ul {
        flex-direction: row;
        display: flex;
        justify-content: space-around;
        flex-basis: 40%;
    }

    .About-company-style-line {
        width: 240px;
    }

    .Row {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .Block {
        flex: 0 0 32%;
        align-items: center;
        padding-bottom: 5%;
        padding-top: 5%;
        display: flex;
        flex-direction: row;
        background: #F5F5FA;
        border-radius: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.005em;
        justify-content: flex-start;
        margin-bottom: 15px;
    }

    .Block-img {
        padding-left: 50px;
        width: 70px;
        height: 70px;
        padding-right: 30px;
    }

}

@media screen and (max-width: 900px) {
    * {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.005em;
    }

    h1 {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        margin: 0
    }

    h2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        margin: 0;

    }

    .card-media-Industries {
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        padding-left: 27px;
    }

    .card-media-direction {
        flex-direction: column-reverse;
        display: flex;
        justify-content: space-between;
    }

    .Block-industries {
        padding-top: 5%;
        padding-bottom: 5%;
        background: #1C1E3E;
        border-radius: 20px;
    }

    .Business-card-style-line {
        width: 150px;
    }

    .card-media {
        flex-direction: column;
        display: flex;
        justify-content: space-between
    }

    .Style-line-mobile {
        display: block;
        float: right;
    }

    .Style-line {
        width: 100%;
        display: none;
    }

    .Business-card-Title-Wrapper {
        display: flex;
        padding: 38px 27px;
        flex-direction: column
    }


    .Business-card-Tagline {
        margin-top: 30px;
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.005em;
    }

    .About-company-style-line {
        width: 160px;
    }

    section:not(:first-child) {
        margin-bottom: 67px;
    }

    .footer-text {
        padding-left: 0;
    }

    .navBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }

    .section-header {
        padding-top: 20px;
        padding-bottom: 20px;
        background: white;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .navBar-list {
        display: none;
    }

    .navBar-li, .navBar-li a {
        display: flex;
        text-decoration: none;
        color: #303030;
        font-weight: 500;
        font-size: 20px;
        line-height: 95%;
        letter-spacing: -0.015em;
    }

    body {
        margin: 0;
        padding-right: 5%;
        padding-left: 5%;
    }

    .Block {
        flex: 0 0 32%;
        align-items: center;
        padding-bottom: 60px;
        padding-top: 60px;
        display: flex;
        flex-direction: row;
        background: #F5F5FA;
        border-radius: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.005em;
        padding-left: 48px;
        margin-bottom: 15px;
    }

    .Block-img {
        padding-right: 30px;
        width: 70px;
        height: 70px;
    }

}

.modal {
    z-index: 11;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
}

.modal-header {
    padding: 26px 36px 56px 16px;
}

#btn-close {
    float: right;
}

.modal-body {
    padding: 2px 16px;
}

.modal-content {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
}

.sub-menu {
    position: absolute;
    top: 16px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    display: none;
}

#our-products-desktop-menu:hover .sub-menu {
    display: block;
}

.sub-menu:hover {
    display: block;
}

#our-products-desktop-menu:hover {
    cursor: pointer;
    position: relative;
}

.section-header {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 10;
}

.DataSan-menu {
    font-style: normal;
    color: black;
    text-decoration: none;
}